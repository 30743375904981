
/* VARIABLES */
$clr-primary: #05142b;
$clr-secondary: #12438a;
$clr-tertiary: #343A40;
$clr-light: #D9D9D9;
$clr-dark:  #19191A;
$clr-white: #ececec;

// 3 types of grey from menu
$clr-dark-1: #0f0f0f;
$clr-dark-2: #1f1f1f;
$clr-dark-3: #2e2e2e;
$clr-tab : #8f8f8f;




$amp-font-host: Inter, Roboto, Arial, Verdana, Helvetica, sans-serif;
$amp-font-size: 16px;
$amp-font-color: $clr-dark;

$border-radius: 4px;
$line-light: 1px solid $clr-light;
$line-dark: 1px solid $clr-dark;

// Overrides for Bootstrap
$primary: $clr-primary;
$secondary: $clr-secondary;

/* TODO */
// Nav
// - BG = zwart
// - Hamburger
//   - Icon = 'fa-solid fa-bars'
// - Searchbar
//   - Icon = 'fa-solid fa-magnifying-glass'
//   - Font-size = 15px;
//   - Color = 50% wit
//   - BG = 15% wit
// - Profile
//   - Icon
//     - Container = 32 * 32px
//     - Radius = 16px
//     - Avatar niet gekozen = 'fa-solid fa-user'
//     - Avatar wel gekozen = foto daar in
// - Notifications
//   - Icons
//     - Agenda = 'fa-solid fa-calendar-days'
//     - Alerts = 'fa-solid fa-bell'
//     - Font-size = 24px;
//   - Numbers
//     - BG = clr-primary
//     - Color = white
//     - Fontsize = 12px
//
// Menu
// - Font-size = 16px;
// - Icons
//   - Favorites = 'fa-solid fa-star'
//   - Agenda = 'fa-solid fa-calendar'
//   - Chat = 'fa-solid fa-comments'
//   - Users = 'fa-solid fa-users'
//   - Wiki = 'fa-solid fa-book'
//   - Checkbox uit(aan) = 'fa-solid fa-square(-check)'


/* HELPER > WHICH BOOTSTRAP 5 BREAKPOINT IS ACTIVE? */
// Vic: A video explaining what this does is here: https://youtu.be/dQw4w9WgXcQ
.which-res {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 2000;
  background-color: red;
  color: #ffffff;
  width: 100px;
  height: auto;
  text-align: center;
  font-weight: bold;
  font-size: 30px;
  border: 4px solid #ffffff;
  &::after {
    @media screen and (max-width: 575px)                            { content: 'XS'; }
    @media screen and (min-width: 576px) and (max-width: 767px)     { content: 'SM'; }
    @media screen and (min-width: 768px) and (max-width: 991px)     { content: 'MD'; }
    @media screen and (min-width: 992px) and (max-width: 1199px)    { content: 'LG'; }
    @media screen and (min-width: 1200px) and (max-width: 1399px)   { content: 'XL'; }
    @media screen and (min-width: 1400px)                           { content: 'XXL'; }
  }
}




/* BREAKPOINTS BOOTSTRAP 5
// Mixins with media queries that define 'up, 'down', 'only' and 'between'
// breakpoints. You can use these to define visibility of your classes,
// for each or a range of specific devices.
// See https://getbootstrap.com/docs/5.1/layout/breakpoints/#media-queries

// XS is often not needed, because Bootstrap is mobile-first

// SM and up        @include media-breakpoint-up(sm) { }
// MD and up        @include media-breakpoint-up(md) { }
// LG and up        @include media-breakpoint-up(lg) { }
// XL and up        @include media-breakpoint-up(xl) { }
// XXL and up       @include media-breakpoint-up(xxl) { }

// XS and down      @include media-breakpoint-down(sm) { }
// SM and down      @include media-breakpoint-down(md) { }
// MD and down      @include media-breakpoint-down(lg) { }
// LG and down      @include media-breakpoint-down(xl) { }
// XL and down      @include media-breakpoint-down(xxl) { }

// XS only          @include media-breakpoint-only(xs) { }
// SM only          @include media-breakpoint-only(sm) { }
// MD only          @include media-breakpoint-only(md) { }
// LG only          @include media-breakpoint-only(lg) { }
// XL only          @include media-breakpoint-only(xl) { }
// XXL only         @include media-breakpoint-only(xxl) { }

// MD to XL         @include media-breakpoint-between(md, xl) { }
// Etc

// Example
// Hide starting at 'min-width: 0', and then show at the 'SM' breakpoint
//   .custom-class {
//     display: none;
//   }
//   @include media-breakpoint-up(sm) {
//     .custom-class {
//       display: block;
//     }
//   }

*/


/* BASE > HOST */
:host {
  font-family: $amp-font-host;
  font-size: $amp-font-size;
  color: $amp-font-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* BASE > BODY */
html, body {
  position: relative;
  margin: 0;
  padding: 0;
  font-family: $amp-font-host;
  font-size: $amp-font-size;
  color: $amp-font-color;
  background-color: #ffffff;
  overflow-y: hidden;
}

body, #root, .wrap, .container-fluid {
  height: 100%;
  min-height: 100vh;
}

.middle {
  min-height: calc(100vh - 40px);
}

/* BASE > CONTAINERS */
.wrap, header, main, footer {
  position: relative;
}


/* BASE > TEXT */
// First normalize them, then redefine them below
// 8px grid system = 8/16/24/32/40/48/56/64/72/80
// Do not add UL, because this fucks up the menu
h1, h2, h3, h4, h5, h6, ul:not(.navbar-nav, .dropdown-menu) {
  position: relative;
  margin: 0;
  margin-bottom: 10px;
  font-weight: 400; // Regular
  font-size: 32px;
  color: $clr-dark;
}

a {
  color: $clr-primary;
  text-decoration: none;
  outline: none;
  &:hover, &:focus {
    color: $clr-tertiary;
    outline: none;
  }
  &:active {
    outline: none;
  }
}

.middle a:hover {
  text-decoration: underline;
}

b, strong {
  font-weight: 700; // Bold
}

hr {
  width: 100%;
  height: 1px !important; // Or Reboot changes it to 1px
  background-color: rgba(0,0,0,1);
  opacity: 1;
}

blockquote {
  font-size: 22px;
  font-style: italic;
  color: rgba($clr-dark, 0.5);
  padding: 4px;
  margin-left: 20px;
  padding-left: 20px;
  border: 0px;
  border-left: 4px solid rgba($clr-primary, 0.3);
}


/* BASE > BUTTONS */
// .btn-primary, .btn-secondary {
//   font-weight: 600; // SemiBold
//   font-size: 20px;
//   padding: 5px 20px;
//   color: #000000;
//   border-radius: 10px;
//   &:hover, &:focus {
//     color: rgba(#000000, 0.6);
//   }
// }

// .btn-secondary {
//   color: $clr-primary;
//   &:hover, &:focus {
//     color: rgba(#ffffff, 0.9);
//   }
// }

.btn-primary {
  background-color: #0A58CA;
  border-color: #0A58CA;
}


// login form
.login-form {
  width: 25%;
  margin: 0 auto;
  padding: 10px;
  font-size: 14px;
  padding-top: 0px;
}
.login-form span {
  margin-top: 8px;
}
.login-form a {
  color: #2596f3;
  text-decoration: none;
  &:hover, &:focus {
    color: $clr-secondary;
  }
}
.login-form .login-heading {
  margin-bottom: 10px;
  border-bottom: $clr-light 1px solid;
}
.login-form .login-heading  strong {
  font-size: 20px;
  color: $clr-tertiary;
}
.login-form .btn {
  width: 100%;
  margin-top: 10px;
  border-radius: 3px;
  font-weight: 600;
  font-size: 14px;
  padding: 6px;
  color: $clr-white;
}
.login-form .btn:not(form .btn) svg {
  position: absolute;
  left: 28%;
  margin-top: 3px;
}
.login-form hr {
  margin-top: 20px;
  margin-bottom: 20px;
  color: $clr-light;
}
.login-form form div {
  margin-bottom: 10px;
}
.login-form form input {
  margin-top: 8px;
}
.login-form form label {
  margin-top: 6px;
}
.login-form form .btn {
  width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: -10px;
}

/* BASE > HELPERS */
.mt-p5                { margin-top: 5px !important; }
.mt-p10               { margin-top: 10px !important; }
.mt-p15               { margin-top: 15px !important; }
.mt-p20               { margin-top: 20px !important; }
.mt-p30               { margin-top: 30px !important; }
.mt-p40               { margin-top: 40px !important; }

.mr-p5                { margin-right: 5px !important; }
.mr-p10               { margin-right: 10px !important; }
.mr-p15               { margin-right: 15px !important; }
.mr-p20               { margin-right: 20px !important; }
.mr-p30               { margin-right: 30px !important; }
.mr-p40               { margin-right: 40px !important; }

.mb-p5                { margin-bottom: 5px !important; }
.mb-p10               { margin-bottom: 10px !important; }
.mb-p15               { margin-bottom: 15px !important; }
.mb-p20               { margin-bottom: 20px !important; }
.mb-p30               { margin-bottom: 30px !important; }
.mb-p40               { margin-bottom: 40px !important; }

.ml-p5                { margin-left: 5px !important; }
.ml-p10               { margin-left: 10px !important; }
.ml-p15               { margin-left: 15px !important; }
.ml-p20               { margin-left: 20px !important; }
.ml-p30               { margin-left: 30px !important; }
.ml-p40               { margin-left: 40px !important; }

.spacer               { flex: 1; }
.no-margin            { margin: 0 !important; }

.text-left            { text-align: left; }
.text-center          { text-align: center; }
.text-right           { text-align: right; }
.text-uppercase       { text-transform: uppercase; }
.text-lowercase       { text-transform: lowercase; }

.clearer              { clear: both; }
.clearer-left         { clear: left; }
.clearer-right        { clear: right; }




// diagonal striped background
.striped {
  background-image: linear-gradient(45deg, rgba(0,0,0,0.1) 25%, transparent 25%, transparent 50%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0.1) 75%, transparent 75%, transparent);
  background-size: 20px 20px;
}
.content table {
  font-size: 16px;
}

.content table td:has(.row) {
  padding-left: 6px;
}


.content table th {
  font-weight: 600;
  color: $clr-tertiary;
  padding: 6px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid $clr-light;
  background-color: $clr-light;
}

.content table td {
  margin: 0px;
  padding: 6px;
  padding-left: 10px;
  padding-right: 10px;
  border: 0px;
  border-bottom: 1px solid $clr-light;
}






// Vic: I'm going to review the code below, and integrate it with my own version.
.top {
  background-color: black;
  color: $clr-light;
  height: 40px;
  padding: 6px;
}

.top input{
}

.middle {

}

.content {
  padding-top: 12px;
}

.navigation{
  background-color: $clr-dark-2;
  color: $clr-light;
  margin-left: -12px;
}

.navigation-item {
  padding: 6px;
  border-bottom: 1px solid $clr-light;
  width: 100%;
  min-width: 100%;
}

.navigation-item:hover {
  background-color: $clr-dark;
}

.navigation-item a {
  color: $clr-light;
  text-decoration: none;
}

.user-badge {
}

.user-badge .dropdown {
  top: 34px;
  right: 68px;
  float: right;
}

.profile-image {
  background-color: $clr-light;
  border: 1px solid $clr-tertiary;
  height: 25px;
  width: 25px;
}


.user-dropdown .dropdown-menu {
  margin-top: -2px; // tmp fix
  background-color: $clr-dark;
  color: $clr-light;
  border-radius: 0px;
}

.user-dropdown .dropdown-menu a {
  color: $clr-light;
  font-size: 14px;
}

.user-dropdown .dropdown-menu a:hover {
  background-color: $clr-tertiary;
}

.user-dropdown .dropdown-menu hr {
  color: $clr-light;
  background-color: $clr-light;
}

.icons {
  display: block;
  float: left;
  width: 50px;
  background-color: $clr-dark-1;
  color: $clr-light;
  min-height: calc(100vh - 40px);
}
.icons svg {
}

.icons hr {
  color: $clr-tab;
  width: 60%;
  margin: 0 auto;
  margin-top: 6px;
  margin-bottom: 6px;
}


.icons a {
  display: block;
  margin: auto;
  margin-left: 2px;
  color: $clr-tab;
  text-decoration: none;
}

.icons a:hover {
  text-decoration: none;
  color: $clr-white;
}

.icons .nav-item .active {
  margin-left: 2px;
  max-width: 46px;
  background-color: $clr-dark-1;
  color: $clr-light;
}

.tabs {
  float: right;
  width: calc(100% - 50px);
  color: $clr-light;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 12px;
  color: $clr-tab;
  margin-top: 0px;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid $clr-tab;
}

.separator:not(:empty)::before {
  margin-right: .25em;
}

.separator:not(:empty)::after {
  margin-left: .25em;
}

.tab-content {
  min-height: calc(100vh - 40px);
  font-size: 14px;
}

.tab-content a {
  padding: 6px;
  padding-left: 10px;
  color: $clr-tab;
  text-decoration: none;
  display: block;
  background-color:  $clr-dark-2;
}

.tab-home a {
  padding: 10px;
  padding-left: 10px;
  min-height: 42px;
  max-height: 42px;
  color: $clr-tab;
  text-decoration: none;
  display: block;
  background-color:  $clr-dark-2;
}

.tab-content a:hover {
  text-decoration: none;
  background-color: $clr-dark-3;
}


.tab-content strong {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 5px;

  font-weight: 200;
  display: block;
  text-transform: capitalize;

  border-bottom: 1px solid $clr-light;
  width: auto;
}



.top .search { // container

}

.top .search-bar{
  position: relative;
  margin-top:-28px;
  padding-top: 8px;

  border: 1px solid $clr-dark-2;
  border-radius: 4px;
  width: 100%;
  max-height: 30px;
  color: $clr-tab;
  background-color: $clr-dark-2;
  padding-left: 36px;

  &::placeholder {
    color: $clr-tab;
  }

  &:focus { // why is there still a blue outline ?!
    outline: none;
    border: 1px solid $clr-dark-3 !important;
    background-color: $clr-dark-3;
  }
}

.top .search-icon {
  position: relative;
  margin-left: 10px;
  margin-top: 2px;
  width: fit-content;
  z-index: 1000;
}


.middle .search-bar {

  margin-left: -10px;
  padding-left: 0px;

  .search-icon {
    position: absolute;
    margin-left: 10px;
    margin-top: -26px;
    width: fit-content;
    z-index: 1000;
  }

  input {

    padding-left: 36px;

    &::placeholder {
      color: $clr-tab;
    }

    &:focus { // why is there still a blue outline ?!
      outline: none;
      border: 1px solid $clr-dark-3 !important;
      background-color: $clr-dark-3;
    }

  }

}

.middle hr {
  color: $clr-light;
}


// most pages have these 3 rows
// .content .breadcrumbs {

// }

// .content .head {

// }

// .content .body {

// }
